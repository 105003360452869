import React, { useEffect, useState } from 'react';
import {
  Button,
  Fade,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  SelectChangeEvent,
  TextField,
  Alert,
} from '@mui/material';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import moment from 'moment';

import { Reimbursement } from 'us-web-services';
import DateUtil from '../../../util/DateUtil';
import ModalStyles from '../../../styles/ModalStyles';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  reimbursement: Reimbursement;
  updateReimbursement: (reimbursement: number, reimbursementData: any) => void;
}

function ReimbursementEdit(props: Props) {
  const { open, setOpen, reimbursement, updateReimbursement } = props;
  const { classes } = ModalStyles();
  const [selectedCareTypeCategory, setSelectedCareTypeCategory] = useState<string>(
    reimbursement.careTypeCategory || '',
  );
  const [careTypeCategories, setCareTypeCategories] = useState<string[]>([]);
  const [error, setError] = useState<string>('');
  const [startDate, setStartDate] = useState<Date>();
  const [startTime, setStartTime] = useState<moment.Moment>();
  const [endDate, setEndDate] = useState<Date>();
  const [endTime, setEndTime] = useState<moment.Moment>();

  const handleClose = () => {
    setOpen(false);
    setError('');
  };

  const getCareTypeCategories = async () => {
    const categories: string[] = [
      'Children - In-home',
      'Children - Daycare',
      'Children - Camps',
      'Children - Preschool',
      'Tutoring',
      'Elder Care',
      'Pet Care',
      'Household Services',
    ];

    setCareTypeCategories(categories);
  };

  const onCareTypeCategorySelect = (event: SelectChangeEvent<string>) => {
    setSelectedCareTypeCategory(event.target.value);
  };

  const getCurrentYearEnd = () => {
    const now = new Date();

    return new Date(now.getFullYear(), 11, 31, 23, 59, 59);
  };

  const getDateTime = (date: Date, time?: moment.Moment) => {
    if (!date) return null;

    const result = moment(date);

    if (time) {
      result.hours(time.hours());
      result.minutes(time.minutes());
    } else {
      result.hours(0);
      result.minutes(0);
    }

    result.seconds(0);

    return result.format(DateUtil.dateTimeFormat);
  };

  const validateDates = (): boolean => {
    if (!startDate || !endDate) {
      setError('Start date and end date are required');

      return false;
    }

    if (endDate < startDate) {
      setError('End date cannot be before start date');

      return false;
    }

    const yearEnd = getCurrentYearEnd();

    if (endDate > yearEnd) {
      setError(`End date cannot be after ${moment(yearEnd).format('MM/DD/YYYY')}`);

      return false;
    }

    setError('');

    return true;
  };

  const saveReimbursement = async () => {
    if (!validateDates()) {
      return;
    }

    const startDateTime = getDateTime(startDate, startTime);
    const endDateTime = getDateTime(endDate, endTime);

    const reimbursementData = {
      id: reimbursement.usOonId,
      startDate: startDateTime,
      endDate: endDateTime,
      careTypeCategory: selectedCareTypeCategory,
    };

    updateReimbursement(reimbursement.usOonId, reimbursementData);
  };

  useEffect(() => {
    getCareTypeCategories();

    const startMoment = moment(reimbursement.startDate);
    const endMoment = moment(reimbursement.endDate);

    setStartDate(startMoment.toDate());
    setStartTime(startMoment);
    setEndDate(endMoment.toDate());
    setEndTime(endMoment);

    setSelectedCareTypeCategory(reimbursement.careTypeCategory || '');
    setError('');
  }, [reimbursement.id, reimbursement.endDate, reimbursement.startDate, reimbursement.careTypeCategory]);

  return (
    <Modal
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
      className={classes.modal}
      closeAfterTransition
      onClose={handleClose}
      open={open}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <h2 id='plan-edit-modal-title'>Edit Out of Network</h2>

          {error && (
            <Alert severity='error' sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id='care-type-category-label'>Care type category</InputLabel>
                <Select
                  labelId='care-type-category-label'
                  id='care-type-category-select'
                  value={selectedCareTypeCategory}
                  onChange={onCareTypeCategorySelect}
                  label='Care type category'
                >
                  {careTypeCategories.map(item => (
                    <MenuItem value={item} key={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <DatePicker
                label='Start Date'
                value={startDate}
                onChange={(newDate) => {
                  setStartDate(newDate);
                  setError('');
                }}
                renderInput={(inputProps) => (
                  <TextField
                    {...inputProps}
                    required
                    fullWidth
                    name='startDate'
                    id='startDate'
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TimePicker
                label='Start Time (Optional)'
                value={startTime}
                onChange={(newTime) => {
                  setStartTime(newTime);
                  setError('');
                }}
                renderInput={(inputProps) => (
                  <TextField
                    {...inputProps}
                    fullWidth
                    name='startTime'
                    id='startTime'
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <DatePicker
                label='End Date'
                value={endDate}
                onChange={(newDate) => {
                  setEndDate(newDate);
                  setError('');
                }}
                maxDate={getCurrentYearEnd()}
                renderInput={(inputProps) => (
                  <TextField
                    {...inputProps}
                    required
                    fullWidth
                    name='endDate'
                    id='endDate'
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TimePicker
                label='End Time (Optional)'
                value={endTime}
                onChange={(newTime) => {
                  setEndTime(newTime);
                  setError('');
                }}
                renderInput={(inputProps) => (
                  <TextField
                    {...inputProps}
                    fullWidth
                    name='endTime'
                    id='endTime'
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Button
                id='close'
                color='secondary'
                onClick={handleClose}
                variant='contained'
                sx={{ marginRight: 1 }}
              >
                Close
              </Button>
              <Button
                id='save'
                color='primary'
                onClick={saveReimbursement}
                variant='contained'
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </div>
      </Fade>
    </Modal>
  );
}

export default ReimbursementEdit;
