import axios, { AxiosRequestConfig } from 'axios';
import { DataResponse, Facility, PagedResponse } from '../types';

const SEARCH_URL = '/v2/kinside/search';
const SEARCH_INFO_URL = '/v2/kinside-search-infos';

interface KinsideSearchParams {
  ageRanges?: string; // comma-separated list of age_group IDs. Only centers providing care to ALL specified ranges are returned.
  distance?: number; // In miles; defaults to 30
  facilityClass?: 'center' | 'homebased';
  latitude: number;
  limit?: number; // number of results to return per page; defaults to 12
  longitude: number;
  maximumRate?: number; // upper limit for rate
  page?: number; // defaults to 1
  philosophies?: string; // comma-separated list of philosophy IDs. Centers matching ANY of the specified philosophies are returned.
  scheduleTypes?: string; // comma-separated list of schedule type IDs. Only centers that match ALL specified types are returned.
  openSeats?: boolean; // limits results to only locations with open seats available (default false)
  sort?: 'distance' | 'price';
  userId?: number;
};

interface KinsideSearchDetails {
  id?: number;
  parentId?: number;
  latitude?: number;
  longitude?: number;
  distance?: number;
  ageRanges?: string;
  facilityClass?: 'center' | 'homebased';
  maximumRate?: number;
  openSeats?: boolean;
  philosophies?: string;
  scheduleTypes?: string;
  startDate?: string;
  created?: Date;
  createdBy?: number;
  modified?: Date;
  modifiedBy?: number;
}

interface KinsideSearchInfo {
  parentId: number;
  searchId?: number;
  resultCount: number;
  parentKinsideSearchDTO: KinsideSearchDetails;
  created?: Date;
  createdBy?: number;
  modified?: Date;
  modifiedBy?: number;
}

class KinsideService {
  static async search(params: KinsideSearchParams): Promise<PagedResponse<Facility>> {
    try {
      const response = await axios.get(SEARCH_URL, { params });

      if (response.data?.error) {
        throw new Error(response.data.data.error);
      }

      return response;
    } catch (error) {
      console.warn(error); // eslint-disable-line no-console

      const emptyResponse: PagedResponse<Facility> = {
        data: {
          data: [],
          meta: {
            firstPage: true,
            lastPage: true,
            pageCount: 1,
            page: 1,
            size: 0,
          },
        },
      };

      return emptyResponse;
    }
  }

  static saveSearchInfo(data: KinsideSearchInfo, config?: AxiosRequestConfig): Promise<DataResponse<KinsideSearchInfo>> {
    return axios.post(SEARCH_INFO_URL, data, config);
  }
}

export { KinsideService };
