import React, { useCallback, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import toast from 'react-hot-toast';
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  Paper,
  Typography,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle';
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons/faTimesCircle';
import moment from 'moment';

import { axios, Company } from 'us-web-services';
import DisplayService from '../../../../util/DisplayService';
import PageStyles from '../../../../styles/PageStyles';

interface Props extends RouteComponentProps {
  company: Company;
}

function CompanyCensus(props: Props) {
  const { company } = props;
  const [file, setFile] = useState(null);
  const [parsed, setParsed] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [uploadSuccessful, setUploadSuccessful] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);

  const fileInput = React.createRef<HTMLInputElement>();
  const { classes } = PageStyles();

  const openConfirm = () => {
    setShowConfirm(true);
  };

  const closeConfirm = () => {
    setShowConfirm(false);
  };

  const showError = useCallback(async error => {
    // have to parse this since it's a blob type
    const responseText = await error.response.data.text();
    const responseObject =
      responseText.length > 0 ? JSON.parse(responseText) : undefined;

    const displayedError = DisplayService.getErrorResponse(
      error,
      'There was an error uploading the CSV.',
    );

    displayedError.autoDismiss = false;
    displayedError.message = `Error: ${responseObject?.errors?.[0]?.message ?? 'Unexpected failure'}`;
    toast.error(displayedError.message);
    setUploadSuccessful(false);
  }, []);

  const resetAfterUpload = () => {
    setFile(null);
    setParsed(false);
    setUploadSuccessful(true);
  };

  const processFile = async () => {
    resetAfterUpload();
    if (!fileInput.current.files[0]) {
      toast.error('Uploaded file does not exist');

      return;
    }

    setUploadSuccessful(null);
    setFile(fileInput.current.files[0]);
    const reader = new window.FileReader();

    setParsed(true);
    reader.readAsText(fileInput.current.files[0]);
  };

  const uploadFile = async () => {
    const formData = new FormData();

    formData.append('file', file);
    setShowConfirm(false);
    setUploading(true);

    await axios
      .post(`/v2/company-employee-census-delta/${company.id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        responseType: 'blob',
      })
      .then(response => {
        const url = window.URL.createObjectURL(
          // https://stackoverflow.com/a/53230807/13873934
          new Blob([response.data], { type: 'application/zip' }),
        );
        const link = document.createElement('a');

        link.href = url;
        const fileName = company.name + moment().format('YYYY-MM-DD');

        link.setAttribute('download', `${fileName}.zip`);
        document.body.appendChild(link);
        link.click();
        resetAfterUpload();
      })
      .catch(showError)
      .finally(() => {
        setUploading(false);
      });
  };

  return (
    <Paper className={classes.paper}>
      <Dialog open={showConfirm} onClose={closeConfirm}>
        <DialogTitle>Ready to upload?</DialogTitle>
        <DialogActions>
          <Button onClick={closeConfirm} color='primary'>
            Cancel
          </Button>
          <Button
            onClick={uploadFile}
            disabled={uploading}
            color='primary'
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Typography variant='h6' gutterBottom>
        Census File Upload
      </Typography>
      <p>
        Upload a census .csv file to audit active employees. Tool returns a list
        of employees to be deactivated and a list of employees to be safelisted.
        This tool requires a census key to be set correctly above based on the
        file.
      </p>
      <Grid container spacing={2}>
        <Grid item lg={4}>
          <input
            id='csv-file-company-census'
            type='file'
            ref={fileInput}
            onChange={processFile}
          />
        </Grid>
        <Grid item lg={4}>
          {parsed && (
            <Button
              color='primary'
              disabled={uploading}
              onClick={openConfirm}
              variant='contained'
            >
              {uploading ? 'Uploading...' : 'Upload'}
            </Button>
          )}
          {uploadSuccessful === true && (
            <div style={{ color: 'green' }}>
              <FontAwesomeIcon
                style={{
                  fontSize: 16,
                  marginRight: '0.75rem',
                }}
                icon={faCheckCircle}
              />
              <span>Upload successful!</span>
            </div>
          )}
          {uploadSuccessful === false && (
            <div style={{ color: 'red' }}>
              <FontAwesomeIcon
                style={{
                  fontSize: 16,
                  marginRight: '0.75rem',
                }}
                icon={faTimesCircle}
              />
              <span>Error occurred</span>
            </div>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
}

export default withRouter(CompanyCensus);
