import React, { useCallback, useState } from 'react';
import {
  Checkbox,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  TextFieldProps,
  Typography,
  Card,
  CardContent, CardMedia,
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import { useDropzone } from 'react-dropzone';
import toast from 'react-hot-toast';

import { axios, User } from 'us-web-services';
import { RoleConstants } from '../../constants';
import DateUtil from '../../util/DateUtil';

interface Props {
  user: User;
  setUser(user: User): void;
}

export default function UserEditForm(props: Props) {
  const { user, setUser } = props;
  const [active, setActive] = useState(user.active);
  const [email, setEmail] = useState(user.email);
  const [firstName, setFirstName] = useState(user.firstName);
  const [lastName, setLastName] = useState(user.lastName);
  const [removePhoto, setRemovePhoto] = useState(false);
  const [superUser, setSuperUser] = useState(user.superUser);
  const [paymentAdmin, setPaymentAdmin] = useState(user.paymentAdmin);
  const [managerAdmin, setManagerAdmin] = useState(user.managerAdmin);
  const [securityAdmin, setSecurityAdmin] = useState(user.securityAdmin);
  const [productAdmin, setProductAdmin] = useState(user.productAdmin);
  const [pictureUrl, setPictureUrl] = useState(user.pictureUrl);
  const [policyAccepted, setPolicyAccepted] = useState(
    user.policyAccepted ? user.policyAccepted : null,
  );

  const uploadErrorMessage = 'Error uploading file';

  const onDrop = useCallback(async (acceptedFiles: (string | Blob)[]) => {
    const formData = new FormData();

    if (acceptedFiles.length > 0) {
      try {
        formData.append('file', acceptedFiles[0]);
        formData.append('userId', user.id.toString());

        const uploadResponse = await axios.post('/v2/user-pictures', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        user.pictureUrl = uploadResponse.data.data.url;
        setPictureUrl(uploadResponse.data.data.url);
        setUser(user);
        toast.success('Photo uploaded successfully');
      } catch (e) {
        if (e.response?.data?.errors?.length) {
          toast.error(e.response.data.errors[0].message);
        } else {
          toast.error(uploadErrorMessage);
        }
      }
    } else {
      toast.error(uploadErrorMessage);
    }
  },
  [],
  );
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const getRoles = () => {
    const roles = [];

    if (user.parent) {
      roles.push(RoleConstants.PARENT);
    } else if (user.sitter) {
      roles.push(RoleConstants.SITTER);
    } else {
      roles.push(RoleConstants.AGENCY);
    }

    if (user.superUser) {
      roles.push(RoleConstants.SUPER_USER);
    }

    if (user.paymentAdmin) {
      roles.push(RoleConstants.PAYMENT_ADMIN);
    }

    if (user.managerAdmin) {
      roles.push(RoleConstants.MANAGER_ADMIN);
    }

    if (user.securityAdmin) {
      roles.push(RoleConstants.SECURITY_ADMIN);
    }

    if (user.productAdmin) {
      roles.push(RoleConstants.PRODUCT_ADMIN);
    }

    return roles;
  };

  const updateFirstName = event => {
    setFirstName(event.target.value);
    user.firstName = event.target.value;
    setUser(user);
  };

  const updateLastName = event => {
    setLastName(event.target.value);
    user.lastName = event.target.value;
    setUser(user);
  };

  const updateEmail = event => {
    setEmail(event.target.value);
    user.email = event.target.value;
    setUser(user);
  };

  const updatePassword = event => {
    user.password = event.target.value;
    setUser(user);
  };

  const updatePassword2 = event => {
    user.password2 = event.target.value;
    setUser(user);
  };

  const updateActive = event => {
    setActive(event.target.value);
    user.active = event.target.value;
    setUser(user);
  };

  const updateProfilePhoto = () => {
    setRemovePhoto(!removePhoto);
    user.removePhoto = !removePhoto;
    setUser(user);
  };

  const updatePolicyAccepted = moment => {
    const newPolicyAccepted = DateUtil.getDateTimeString(moment);

    setPolicyAccepted(newPolicyAccepted);
    user.policyAccepted = newPolicyAccepted;
    setUser(user);
  };

  const updateSuperUser = () => {
    setSuperUser(!superUser);
    user.superUser = !superUser;
    user.roles = getRoles();
    setUser(user);
  };

  const updatePaymentAdmin = () => {
    setPaymentAdmin(!paymentAdmin);
    user.paymentAdmin = !paymentAdmin;
    user.roles = getRoles();
    setUser(user);
  };

  const updateManagerAdmin = () => {
    setManagerAdmin(!managerAdmin);
    user.managerAdmin = !managerAdmin;
    user.roles = getRoles();
    setUser(user);
  };

  const updateSecurityAdmin = () => {
    setSecurityAdmin(!securityAdmin);
    user.securityAdmin = !securityAdmin;
    user.roles = getRoles();
    setUser(user);
  };

  const updateProductAdmin = () => {
    setProductAdmin(!productAdmin);
    user.productAdmin = !productAdmin;
    user.roles = getRoles();
    setUser(user);
  };

  return (
    <>
      <Typography variant='h6' gutterBottom>
        Account Details
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id='firstName'
            name='firstName'
            label='First name'
            fullWidth
            autoComplete='fname'
            value={firstName}
            onChange={updateFirstName}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id='lastName'
            name='lastName'
            label='Last name'
            fullWidth
            autoComplete='lname'
            value={lastName}
            onChange={updateLastName}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id='email'
            name='email'
            label='Email'
            fullWidth
            autoComplete='email'
            value={email}
            onChange={updateEmail}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id='password'
            name='password'
            label='Password'
            fullWidth
            autoComplete='new-password'
            type='password'
            onChange={updatePassword}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id='password2'
            name='password2'
            label='Retype Password'
            fullWidth
            autoComplete='password2'
            type='password'
            onChange={updatePassword2}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormLabel component='legend'>Status</FormLabel>
          <RadioGroup
            aria-label='Status'
            name='status'
            value={`${active}`}
            onChange={updateActive}
          >
            <FormControlLabel value='true' control={<Radio />} label='Active' />
            <FormControlLabel
              value='false'
              control={<Radio />}
              label='Blocked'
            />
          </RadioGroup>
        </Grid>
        <Grid item xs={12} sm={6}>
          <DateTimePicker
            inputFormat={DateUtil.dateTimeReadable}
            value={policyAccepted}
            onChange={updatePolicyAccepted}
            renderInput={(pickerProps: TextFieldProps) => (
              <TextField
                {...pickerProps}
                fullWidth
                name='policyAccepted'
                label='Policy Accepted'
                id='policyAccepted'
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormLabel component='legend'>Delete Profile photo</FormLabel>
          <Checkbox
            checked={removePhoto}
            onChange={updateProfilePhoto}
            inputProps={{
              'aria-label': 'primary checkbox',
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant='h6' gutterBottom>
            Profile Photo
          </Typography>
          {pictureUrl ? (
            <CardMedia
              style={{
                width: 50,
                height: 50,
                marginBottom: 10,
              }}
              image={pictureUrl}
              title={`${user.firstName} picture`}
            />
          ) : (
            ''
          )}
          <section
            {...getRootProps()}
            style={{
              width: '50%',
              height: '50%',
              flex: 1,
              textAlign: 'center',
              borderWidth: 2,
              borderRadius: 2,
              borderColor: '#eeeeee',
              borderStyle: 'dashed',
              backgroundColor: '#fafafa',
              color: '#bdbdbd',
              outline: 'none',
              transition: 'border .24s ease-in-out',
            }}
          >
            <input {...getInputProps()} />
            <p>Drag and drop the profile photo here, or click to select the photo</p>
          </section>
        </Grid>
      </Grid>
      <Card style={{ marginTop: 20 }}>
        <CardContent>
          <Typography component='h5' variant='h5' style={{ marginBottom: 5 }}>
            Roles
          </Typography>
          <br />
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormLabel component='legend'>Super User?</FormLabel>
              <Checkbox
                checked={superUser}
                onChange={updateSuperUser}
                inputProps={{
                  'aria-label': 'primary checkbox',
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormLabel component='legend'>Payment Admin?</FormLabel>
              <Checkbox
                checked={paymentAdmin}
                onChange={updatePaymentAdmin}
                inputProps={{
                  'aria-label': 'primary checkbox',
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormLabel component='legend'>Manager Admin?</FormLabel>
              <Checkbox
                checked={managerAdmin}
                onChange={updateManagerAdmin}
                inputProps={{
                  'aria-label': 'primary checkbox',
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormLabel component='legend'>Security Admin?</FormLabel>
              <Checkbox
                checked={securityAdmin}
                onChange={updateSecurityAdmin}
                inputProps={{
                  'aria-label': 'primary checkbox',
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormLabel component='legend'>Product Admin?</FormLabel>
              <Checkbox
                checked={productAdmin}
                onChange={updateProductAdmin}
                inputProps={{
                  'aria-label': 'primary checkbox',
                }}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}
