import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Card, CardContent, Typography } from '@mui/material';
import { UserGroup, UserGroupService, UserGroupType, UserGroupTypeService } from 'us-web-services';
import Grid from '@mui/material/Grid';
import SettingToggle from '../common/SettingToggle';
import useUser from '../../store/useUser';
import DisplayService from '../../util/DisplayService';

function UserGroupEdit(props) {
  const { userId } = props;
  const userState = useUser()[0];
  const [groups, setGroups] = useState<UserGroup[]>([]);
  const [groupTypes, setGroupTypes] = useState<UserGroupType[]>([]);

  const showError = error => {
    const displayedError = DisplayService.getErrorResponse(
      error,
      'There was an error updating the user group.',
    );

    toast.error(displayedError.message);
  };

  const getGroups = async (): Promise<void> => {
    const config = {
      params: {
        userId,
      },
    };
    const response = await UserGroupService.getByFilter(config);

    setGroups(response.data.data);
  };

  const getGroupTypes = async (): Promise<void> => {
    const config = {
      params: {
        limit: 100,
        sort: 'created',
        'created.dir': 'desc',
      },
    };
    const response = await UserGroupTypeService.getByFilter(config);

    setGroupTypes(response.data.data);
  };

  const removeUserGroup = async (isChecked: boolean, id: number): Promise<void> => {
    if (!isChecked) {
      try {
        await UserGroupService.delete(id);
        await getGroups();
        toast.success('Group unassigned');
      } catch (e) {
        showError(e);
      }
    }
  };

  const addUserGroup = async (isChecked: boolean, id: number): Promise<void> => {
    if (isChecked) {
      try {
        const data = {
          group: {
            id,
          },
          userId,
        };

        await UserGroupService.create(data);
        await getGroups();
        toast.success('Group assigned');
      } catch (e) {
        showError(e);
      }
    }
  };

  useEffect(() => {
    getGroups();
    getGroupTypes();
  }, [
    userId,
    userState.user.id,
    userState.user.parent,
    userState.user.sitter,
  ]);

  return (
    <Card style={{ marginTop: 20 }}>
      <CardContent>
        <Typography component='h5' variant='h5' style={{ marginBottom: 5 }}>
          Assigned User Groups
        </Typography>
        { !groups.length && (
          <span style={{ marginBottom: 5 }}>No test groups assigned.</span>
        )}
        <Grid container>
          { groups.length > 0 &&
            groups.map(group => (
              <SettingToggle
                id={group.id}
                isChecked
                key={`group-${group.id}`}
                label={`${group?.group?.name} - ${group?.group?.label}`}
                toggleFunction={removeUserGroup}
              />
            ))}
        </Grid>
        <br />
        <Typography component='h5' variant='h5' style={{ marginBottom: 5 }}>
          Unassigned User Groups
        </Typography>
        <Grid container>
          { groupTypes.length > 0 &&
            groupTypes
              .filter(groupType => !groups.length || !groups.some(group => group.group?.id === groupType.id))
              .map(groupType => (
                <SettingToggle
                  id={groupType.id}
                  isChecked={false}
                  key={`groupType-${groupType.id}`}
                  label={`${groupType?.name} - ${groupType?.label}`}
                  toggleFunction={addUserGroup}
                />
              ))}
        </Grid>
      </CardContent>
    </Card>
  );
}

export default UserGroupEdit;
