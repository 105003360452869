import React, { useCallback, useState } from 'react';
import {
  CardMedia,
  Checkbox,
  FormLabel,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { useDropzone } from 'react-dropzone';
import toast from 'react-hot-toast';
import { axios, Company } from 'us-web-services';

interface Props {
  company: Company;
  setCompany(company: Company): void;
}

export default function CompanyEditForm(props: Props) {
  const { company, setCompany } = props;
  const [name, setName] = useState(company.name);
  const [alias, setAlias] = useState(company.alias);
  const [displayName, setDisplayName] = useState(company.displayName);
  const [censusKey, setCensusKey] = useState(company.censusKey ?? '');
  const [emailValidationEnabled, setEmailValidationEnabled] = useState(company.emailValidationEnabled);
  const [useExternalId, setUseExternalId] = useState(
    company.useExternalId == null ? false : company.useExternalId,
  );
  const [logo, setLogo] = useState(company.logoUrl);
  const onDrop = useCallback(async (acceptedFiles: (string | Blob)[]) => {
    const formData = new FormData();

    if (acceptedFiles.length > 0) {
      formData.append('file', acceptedFiles[0]);
      formData.append('companyId', company.id.toString());

      const uploadResponse = await axios.post('/v2/company-logos', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      setLogo(uploadResponse.data.data.url);
    } else {
      toast.error('Error uploading file');
    }
  },
  [company.id],
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const updateName = event => {
    setName(event.target.value);
    company.name = event.target.value;
    setCompany(company);
  };

  const updateDisplayName = event => {
    setDisplayName(event.target.value);
    company.displayName = event.target.value;
    setCompany(company);
  };

  const updateAlias = event => {
    setAlias(event.target.value);
    company.alias = event.target.value;
    setCompany(company);
  };

  const updateCensusKey = event => {
    setCensusKey(event.target.value);
    company.censusKey = event.target.value;
    setCompany(company);
  };

  const updateEmailValidationEnabled = () => {
    const result = !emailValidationEnabled;

    setEmailValidationEnabled(result);
    company.emailValidationEnabled = result;
    setCompany(company);
  };

  const updateUseExternalId = () => {
    const result = !useExternalId;

    setUseExternalId(result);
    company.useExternalId = result;
    setCompany(company);
  };

  return (
    <>
      <Typography variant='h6' gutterBottom>
        Company Details
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            required
            id='name'
            name='name'
            label='Name'
            fullWidth
            autoComplete='name'
            value={name ?? ''}
            onChange={updateName}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id='displayName'
            name='displayName'
            label='Display Name'
            fullWidth
            autoComplete='displayName'
            value={displayName ?? ''}
            onChange={updateDisplayName}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id='alias'
            name='alias'
            label='Alias'
            fullWidth
            autoComplete='alias'
            value={alias ?? ''}
            onChange={updateAlias}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id='censusKey'
            name='censusKey'
            label='Census Header Key'
            fullWidth
            autoComplete='censusKey'
            value={censusKey ?? ''}
            onChange={updateCensusKey}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormLabel htmlFor='emailValidationEnabled' component='legend'>
            Require employee email validation?
          </FormLabel>
          <Checkbox
            id='emailValidationEnabled'
            checked={emailValidationEnabled}
            onChange={updateEmailValidationEnabled}
            inputProps={{
              'aria-label': 'primary checkbox',
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormLabel htmlFor='useExternalId' component='legend'>
            Use external id instead of email?
          </FormLabel>
          <Checkbox
            id='useExternalId'
            checked={useExternalId}
            onChange={updateUseExternalId}
            inputProps={{
              'aria-label': 'primary checkbox',
            }}
          />
        </Grid>
        {company.id ? (
          <Grid item xs={12}>
            <Typography variant='h6' gutterBottom>
              Logo
            </Typography>
            {logo ? (
              <CardMedia
                style={{
                  width: 50,
                  height: 50,
                  marginBottom: 10,
                }}
                image={logo}
                title={`${company.name} logo`}
              />
            ) : (
              ''
            )}
            <section
              {...getRootProps()}
              style={{
                width: '50%',
                height: '50%',
                flex: 1,
                textAlign: 'center',
                borderWidth: 2,
                borderRadius: 2,
                borderColor: '#eeeeee',
                borderStyle: 'dashed',
                backgroundColor: '#fafafa',
                color: '#bdbdbd',
                outline: 'none',
                transition: 'border .24s ease-in-out',
              }}
            >
              <input {...getInputProps()} />
              <p>Drag and drop the logo here, or click to select the logo</p>
            </section>
          </Grid>
        ) : (
          ''
        )}
      </Grid>
    </>
  );
}
